import React from 'react';
import PropTypes from 'prop-types';

import Seo from '../components/Seo';
import Navbar from '../components/Navbar';
import BaseLayout from './BaseLayout';

const AppLayout = ({ children }) => (
  <BaseLayout>
    <Seo />
    <Navbar />
    <main>{children}</main>
  </BaseLayout>
);

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
