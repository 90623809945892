import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';

const ScrollLink = ({
  to, scrollTo, smooth, ...props
}) => {
  const location = useLocation();

  if (location.pathname !== '/') {
    return (
      <RouterLink
        {...props}
        to={{
          pathname: to,
          state: { scrollTo },
        }}
      />
    );
  }

  return <Link {...props} to={scrollTo} smooth />;
};

ScrollLink.propTypes = {
  to: PropTypes.string.isRequired,
  scrollTo: PropTypes.string.isRequired,
  smooth: PropTypes.bool,
};

ScrollLink.defaultProps = {
  smooth: false,
};

export default ScrollLink;
