import React from 'react';

import AppLayout from '../layouts/AppLayout';
import Hero from '../components/Hero'
import Awards from '../components/Awards';
import Benefits from '../components/Benefits';
import ZeroTrust from '../components/ZeroTrust';
import Solution from '../components/Solution';
import CaseStudy from '../components/CaseStudy';
import Blog from '../../blog/components/Blog';
import Quotes from '../components/Quotes';
import FeaturedIn from '../components/FeaturedIn';
import Contact from '../components/Contact'

const IndexPage = () => (
  <AppLayout>
    <Hero />
    <Awards />
    <Benefits />
    <ZeroTrust />
    <Solution />
    <CaseStudy />
    <Blog />
    <Quotes />
    <FeaturedIn />
    <Contact />
  </AppLayout>
);

export default IndexPage;
