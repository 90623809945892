import React from 'react';

import styles from './BookMeeting.module.scss';

const BookMeeting = () => (
  <div className={styles.BookMeeting}>
    <h4>Book a Meeting</h4>
    <p>
      Let us know how we can help.
      <br />
      Book a free consultation now!
    </p>

    <a
      href="https://calendly.com/tamas-cursor-insight/consultation"
      target="_blank"
      rel="noreferrer"
    >
      Book Now
    </a>
  </div>
);

export default BookMeeting;
