import { useEffect, useState } from 'react';
import axios from 'axios';

import { WP_API_URL } from '../../constants';
import formatTags from '../utils/formatTags';

export default function useTags(options) {
  const [state] = useState({
    ...options,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setTags([]);

    const { tag } = state;

    const params = new URLSearchParams();
    params.append('per_page', '100');

    if (tag) {
      params.append('slug', tag);
    }

    axios
      .get(`${WP_API_URL}/tags?${params}`)
      .then((response) => setTags(response.data))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [state, setIsLoading, setTags]);

  return {
    isLoading,
    tags: formatTags(tags),
  };
}
