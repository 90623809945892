import moment from 'moment';

export default function formatDate(dateStr) {
  const date = moment(dateStr);
  const today = moment();

  if (!date.isBefore(today.subtract(7, 'days'))) {
    return date.fromNow();
  }

  return date.format('DD/MM/YYYY');
}
