import React from 'react';
import PropTypes from 'prop-types';

import styles from './ResultsFor.module.scss';

const ResultsFor = ({ children }) => (
  <h1 className={styles.ResutlsFor}>
    Results for: "<span>{children}</span>"
  </h1>
);

ResultsFor.propTypes = {
  children: PropTypes.node,
};

ResultsFor.defaultProps = {
  children: '',
};

export default ResultsFor;
