import React from 'react';
import PropTypes from 'prop-types';

import PostCard from './PostCard';

function Posts({ posts }) {
  const renderedPosts = posts.map((post) => (
    <PostCard key={post.id} post={post} />
  ));

  return <div className="row">
    {renderedPosts}
  </div>;
}

Posts.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

Posts.defaultProps = {
  posts: [],
};

export default Posts;
