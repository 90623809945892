import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import BaseLayout from '../../app/layouts/BaseLayout';
import Seo from '../../app/components/Seo';
import BlogHeader from '../components/BlogHeader';

const BlogLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <BaseLayout>
      <Seo
        title="Graboxy Blog"
        description="About Graboxy's Biometric Cybersecurity"
      />

      <BlogHeader />
      <main style={{ padding: '6rem 0' }}>
        {children}
      </main>
    </BaseLayout>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;
