import React from 'react';
import PropTypes from 'prop-types';

import styles from './Separator.module.scss';

const className = [
  'col-12 col-lg-1 order-2 order-lg-1 mt-2 mb-3 my-lg-2',
  styles.Separator,
].join(' ');

function getStyles(idx) {
  return idx % 2 === 0 ? {
    line: styles.LineStyle2,
    head: styles.HeadStyle2,
    tail: styles.TailStyle2,
  } : {
    line: styles.LineStyle3,
    head: styles.HeadStyle3,
    tail: styles.TailStyle3,
  };
}

const Separator = ({ idx }) => {
  const stiles = getStyles(idx);

  return (
    <div className={className}>
      <div className={stiles.line} />
      <div className={stiles.head} />
      <div className={stiles.tail} />
    </div>
  );
};

Separator.propTypes = {
  idx: PropTypes.number.isRequired,
};

export default Separator;
