import decodeHtml from './decodeHtml';
import formatDate from './formatDate';

import {
  getAuthor,
  getFeaturedImage,
  getTags
} from './formatPosts';

export default function formatPost(post) {
  if (!post) return undefined;

  const { title, date, content, _embedded } = post;

  return {
    date: formatDate(date),
    title: {
      ...title,
      text: decodeHtml(title.rendered),
    },
    content,
    author: getAuthor(_embedded),
    featuredImage: getFeaturedImage(_embedded),
    tags: getTags(_embedded),
  };
}
