import { Link } from 'react-router-dom';
import Logo from '../../../app/components/Logo';

import styles from './Footer.module.scss';

import twitter from './icons/twitter-logo.png';
import facebook from './icons/facebook-logo.png';
import linkedin from './icons/linkedin-logo.png';
import github from './icons/github-logo.png';
import iso from '../../images/ISO27001certified.svg';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer className={styles.Footer}>
    <div className="container">
      <div className="row py-5">
        <div className="col-lg-4">
          <ul>
            <li className="main">
              <a href="" className={styles.FooterItem}>
                <h5>Solutions</h5>
              </a>
            </li>
            <li>
              <a
                href="/solutions/continuous-authentication"
                className={styles.FooterItem}
              >
                Continuous Authentication
              </a>
            </li>
            <li>
              <a
                href="/solutions/multi-factor-authentication"
                className={styles.FooterItem}
              >
                Silent 2FA
              </a>
            </li>
            <li>
              <a
                href="/solutions/device-fingerprinting"
                className={styles.FooterItem}
              >
                Device Fingerprinting
              </a>
            </li>
            <li>
              <a
                href="/solutions/remote-desktop-detection"
                className={styles.FooterItem}
              >
                Remote Desktop Detection
              </a>
            </li>
            <li>
              <a
                href="/solutions/captcha-bot-detection"
                className={styles.FooterItem}
              >
                Captcha &amp; Bot Detection
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-4">
          <ul>
            <li className="main">
              <a href="/technology" className={styles.FooterItem}>
                <h5>Technology</h5>
              </a>
            </li>
            <li className="main">
              <a href="/our-team" className={styles.FooterItem}>
                <h5>Team</h5>
              </a>
            </li>
            <li className="main">
              <a href="/blog" className={styles.FooterItem}>
                <h5>Blog</h5>
              </a>
            </li>
            <img src={iso} className="mt-3" />
          </ul>
        </div>
        <div className="col-lg-4">
          <div className="mb-2">
            <Link to="/privacy" target="_blank" className={styles.FooterItem}>
              Privacy Policy
            </Link>
          </div>
          <div className="mb-3">
            <span>
              &copy; {currentYear},
              {' '}
              <a
                href="https://cursorinsight.com"
                target="_blank"
                rel="noreferrer"
                className={styles.FooterItem}
              >
                Cursor Insight
              </a>
            </span>
          </div>
          <div className="mb-3">
            <Logo />
          </div>
          <div>
            <a
              href="https://twitter.com/cursorinsight"
              target="_blank"
              className={styles.Social}
            >
              <img src={twitter} width={20} />
            </a>
            <a
              href="https://www.facebook.com/cursorinsight"
              target="_blank"
              className={styles.Social}
            >
              <img src={facebook} width={20} />
            </a>
            <a
              href="https://www.linkedin.com/company/cursor-insight/"
              target="_blank"
              className={styles.Social}
            >
              <img src={linkedin} width={20} />
            </a>
            <a
              href="https://github.com/cursorinsight"
              target="_blank"
              className={styles.Social}
            >
              <img src={github} width={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
