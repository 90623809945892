import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';

import Logo from '../../../app/components/Logo';
import sentinelLogo from './logos/inverse-logo-graboxy-sentinel.svg';
import twofaLogo from './logos/inverse-logo-graboxy-2fa.svg';
import anticheatLogo from './logos/inverse-logo-graboxy-anticheat.svg';
import captchaLogo from './logos/inverse-logo-graboxy-captcha.svg';
import ciLogo from './logos/inverse-logo-cursor-insight.svg';
import styles from './BlogHeader.module.scss';

const navClassName = [
  'navbar fixed-top navbar-expand-lg navbar-dark',
  styles.Navbar,
].join(' ');

const navTogglerClassName = [
  'd-block d-lg-none',
  styles.navToggler,
].join(' ');

const onlyDesktopClassName = [
  'd-none d-lg-block',
  styles.onlyDesktop,
].join(' ');

const onlyMobileClassName = [
  'd-block d-lg-none',
  styles.onlyMobile,
].join(' ');

const productMenuClassName = [
  'navbar-nav d-block d-lg-none',
  styles.ProductMenu,
].join(' ');

const BlogHeader = () => (
  <nav className={navClassName}>
    <div className="container">
      <Link
        to="/"
        width={155}
        style={{ marginBottom: '5px' }}
      >
        <Logo />
      </Link>

      <NavLink
        href="/blog"
        className={onlyDesktopClassName}
        style={{ color: '#fff' }}
      >
        Blog
      </NavLink>

      <button
        className={navTogglerClassName}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mt-3 mb-4 my-lg-0">
          <li className="nav-item">
            <NavLink
              href="/blog"
              className={onlyMobileClassName}
              style={{
                color: '#f8e3ff', fontSize: 'calc(1.3rem + 0.6vw)',
                textTransform: 'none', fontWeight: '400'
              }}
            >
              Blog
            </NavLink>
          </li>
          <li className="nav-item">
            <Link
              to="Contact"
              className="btn"
              style={{ padding: '10px 30px' }}
              spy
              smooth
            >
              Contact
            </Link>
          </li>
        </ul>
        <ul className={productMenuClassName}>
          <h3>Graboxy Products</h3>
          <li className="nav-item mb-3">
            <a
              href="https://sentinel.graboxy.com/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <img src={sentinelLogo} alt="Graboxy Sentinel logo" />
            </a>
          </li>
          <li className="nav-item mb-3">
            <a
              href="https://2fa.graboxy.com/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <img src={twofaLogo} alt="Graboxy 2FA logo" />
            </a>
          </li>
          <li className="nav-item mb-3">
            <a
              href="https://anti-cheat.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <img src={anticheatLogo} alt="Graboxy Anti-Cheat logo" />
            </a>
          </li>
          <li className="nav-item mb-3">
            <a
              href="https://captcha.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <img src={captchaLogo} alt="Graboxy Captcha logo" />
            </a>
          </li>

          <h3 className="mt-4">Company Website</h3>
          <li className="nav-item mb-3">
            <a
              href="https://cursorinsight.com/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <img src={ciLogo} alt="Cursor Insight logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default BlogHeader;
