import React from 'react';
import PropTypes from 'prop-types'

import Title from './Title';

const Section = ({ id, title, className, children, gradient }) => (
  <section
    id={id}
    aria-labelledby={`${id}Section`}
    className={className}
  >
    {title && <Title id={`${id}Section`} gradient={gradient}>{title}</Title>}
    {children}
  </section>
);

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  gradient: PropTypes.bool,
};

Section.defaultProps = {
  title: undefined,
  className: undefined,
  children: undefined,
  gradient: false,
};

export default Section;
