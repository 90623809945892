export const WP_API_URL = process.env.REACT_APP_WP_API_URL ?? '';

export const CAPTCHA_URL_BASE = process.env.REACT_APP_CAPTCHA_URL_BASE
  ?? 'https://captcha.graboxy.com/?UNCONFIGURED=1';

export const GRABOXY_API_KEY =
  process.env.REACT_APP_GRABOXY_API_KEY ?? 'UNCONFIGURED';

export const TRAP_SERVER_URL = process.env.REACT_APP_TRAP_SERVER_URL
  ?? 'https://trap.graboxy.com/?UNCONFIGURED=1';

export const GRABOXY_CATEGORY_ID = 39;