import PropTypes from 'prop-types';

const Text = ({ className, children }) => (
  <span className={className}>
    <span>{children}</span>
  </span>
);

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Text.defaultProps = {
  className: undefined,
};

export default Text;
