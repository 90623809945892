import React from 'react';

import usePosts from '../hooks/usePosts';
import BlogLayout from '../layouts/BlogLayout';
import SearchBar from '../components/SearchBar';
import ErrorMessage from '../components/ErrorMessage';
import ResultsFor from '../components/ResultFor';
import Posts from '../components/Posts';
import Pagination from '../components/Pagination';

const BlogPage = () => {
  const {
    isLoading,
    currentPage,
    totalPages,
    resultsFor,
    posts,
    error,
  } = usePosts();

  return (
    <BlogLayout>
      <div className="container">

        <h1 className="text-center text-uppercase mb-4">Blog</h1>

        <SearchBar />

        {(!isLoading && error) && (
          <ErrorMessage content={error.toString()} />
        )}

        {resultsFor && <ResultsFor>{resultsFor}</ResultsFor>}

        {isLoading || (
          <>
            <Posts posts={posts} />
            <Pagination currentPage={currentPage} totalPages={totalPages} />
          </>
        )}
      </div>
    </BlogLayout>
  );
};

export default BlogPage;
