import AppLayout from '../../../../layouts/AppLayout';
import Section from '../../../../components/Section';
import Contact from '../../../../components/Contact';
import styles from './Integration.module.scss';

const Integration = () => {
  return (
    <AppLayout>
      <div className={styles.Integration}>
        <Section>
          <div className="container pt-5 mt-3">
            <div className="row pb-5">
              <div className="col-12">
                <h1>Integration user guide</h1>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h3>Graboxy CAPTCHA integration</h3>
                <p>
                  The Graboxy CAPTCHA is a challenge based CAPTCHA service that
                  scores user behavior and makes it possible to distinguish
                  automated scripts from real human users. The challenge is
                  motion-based and works similar to the
                  {' '}
                  <a href="https://graboxy.com">
                    Graboxy 2FA service
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h3>API keys</h3>
                <p>
                  To use Graboxy CAPTCHA, you need to sign up for an API key
                  pair. The key pair consists of a <i>site key</i> and a
                  {' '}
                  <i>secret key</i>. The <i>site key</i> is used to invoke the
                  Graboxy CAPTCHA service. You will need to use this key when
                  executing the Graboxy CAPTCHA on your website. The
                  {' '}
                  <i>secret key</i> authorizes communication between your
                  application backend and the Graboxy CAPTCHA server. You will
                  need the <i>secret key</i> to verify the user. The
                  {' '}
                  <i>secret key</i> needs to be kept safe, do not share it
                  publicly.
                </p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h3>Placement on your website</h3>
                <p>
                  You can easily place the Graboxy CAPTCHA on your website by
                  performing the steps below:
                </p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h5>1. Load the JavaScript code.</h5>
                <p>You can do this by globally including the bundle:</p>
                <ul>
                  <li>
                    <p>Load it using JavaScript:</p>
                    <pre>
                      <code>
                      {`
window.onload = () => {
  // Add Graboxy CAPTCHA <script> tag
  const scriptUrl =
    'https://captcha.graboxy.com/graboxy-captcha-iife.min.js';

  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', scriptUrl);
  document.head.appendChild(scriptElement);

  scriptElement.onload = () => {
    // Use the Graboxy CAPTCHA
  }
}
`}
                      </code>
                    </pre>
                  </li>
                  <li>
                    <p>
                      Or in a HTML
                      {' '}
                      <code>&lt;script&gt;</code>
                      {' '}
                      tag in the <code>&lt;head&gt;</code> of the page:
                    </p>
                  </li>
                </ul>
                <p>The first approach is recommended.</p>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h5>2. Execute the Graboxy CAPTCHA.</h5>
                <p>
                  You probably want to execute the Graboxy CAPTCHA on form
                  submissions or other sensitive use cases you wish to protect.
                  In order for this to work, you will need a <em>site key</em>.
                  The Graboxy CAPTCHA is exposed in the global scope and you
                  can access it through the <code>graboxyCaptcha</code>
                  {' '}
                  variable. After executing the Graboxy CAPTCHA you will
                  receive a token which can be used to verify the user. We
                  recommend sending the token to your backend server as soon as
                  possible with the request to verify.
                </p>
                <pre>
                  <code>
                    {`
// Submission of a form
var form = document.getElementById('form');
form.addEventListener('submit', (event) =&gt; {
  event.preventDefault();

  // Execute the Graboxy CAPTCHA, then handle the token in a callback
  // function
  const captcha = graboxyCaptcha.default.prototype;
  captcha.execute(siteKey).then((token) =&gt; {
    // Do custom actions here
    / ...
    // Send the token to your backend server
  });
});
`}
                  </code>
                </pre>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h5>3. Verify the user.</h5>
                <p>
                  After executing the Graboxy CAPTCHA, the website displays
                  an onscreen challenge to the user. If the challenge is
                  successfully solved, a token is returned. You can use this
                  token to verify the user. Besides the token that identifies
                  the user, you have to send your <em>secret key</em> along.
                </p>
                <pre>
                  <code>
                    {`
data = JSON.stringify({
  "token": "yourCaptchaToken",
  "secret": "secret-key",
});

options = {
  "hostname": "captcha.graboxy.com",
  "port": 443,
  "path": "/api/0/verify",
  "method": "POST",
  "headers": {
    "Content-Type": "application/json",
    "Content-Length": data.length,
  },
};
`}
                  </code>
                </pre>
                <p>
                  The response for the verify request will be a JSON object of
                  the following form:
                </p>
                <pre>
                  <code>
                    {`
{
  "token": "yourCaptchaToken"                    // The token that identifies
  // the user

  "status": "done" | "failed" | "invalid_token"  // Status of the verification

  "result": "accepted" | "rejected"              // Outcome of the verification
}
`}
                  </code>
                </pre>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h3>Requirements and Limitations</h3>
                <p>
                  <strong>
                    Graboxy CAPTCHA Beta has certain browser, screen
                    resolution and platform limitations. We recommend to turn
                    off the service for unsupported environments.
                  </strong>
                </p>
                <p>We support the following desktop browser versions:</p>
                <ul>
                  <li>
                    <p>Windows 10</p>
                    <ul>
                      <li>Chrome 94+</li>
                      <li>Edge 95</li>
                      <li>Firefox 94</li>
                    </ul>
                  </li>
                  <li>
                    <p>Mac</p>
                    <ul>
                      <li>Chrome 94+</li>
                      <li>Edge 95</li>
                      <li>Firefox 94</li>
                      <li>Safari 14+</li>
                    </ul>
                  </li>
                </ul>
                <p>We support the following screen resolutions:</p>
                <ul>
                  <li>1920x1080</li>
                  <li>1600x900</li>
                  <li>1536x864</li>
                  <li>1440x900</li>
                  <li>1366x768</li>
                  <li>1280x720</li>
                </ul>
                <p>
                  <strong>
                    Currently, we do not support mobile platforms, only desktop.
                  </strong>
                </p>
                <h5>Hide CAPTCHA on mobile devices:</h5>
                <p>
                  In order to hide Graboxy CAPTCHA on mobile devices, you can
                  display an overlay by including this HTML script:
                </p>
                <pre>
                  <code>
                    {`
<div id="mobile-overlay">
  <section>
    <h2>
      Our captcha solution currently only works on desktop browsers.
      <br />
      We’ll launch the mobile version soon, stay tuned
    </h2>
  </section>
</div>
                    `}
                  </code>
                </pre>
                <p>...and by adding the below to your CSS:</p>
                <pre>
                  <code>
                    {`
#mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

#mobile-overlay section h2 {
  text-align: center;
  font-size: 26px;
  line-height: 40px;
}

@media only screen and (max-width: 768px) {
  #mobile-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
                    `}
                  </code>
                </pre>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-12">
                <h3>Miscellaneous</h3>
                <p>
                  The Graboxy CAPTCHA tokens have an expiration date of one
                  hour, so please make sure that you verify your user within an
                  hour after executing the CAPTCHA.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Contact />
      </div>
    </AppLayout>
  );
};

export default Integration;
