import React, { useState } from "react";
import { Link } from 'react-scroll';
import { Card, Col, Row, Fade } from "react-bootstrap";
import Section from '../Section';
import './ZeroTrust.scss';

import frictionless from './gifs/frictionless.gif';
import gui from './gifs/gui.gif';
import maze from './gifs/maze.gif';

import play from './play.svg';

const ZeroTrust = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handleTabClick = (index) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveTab(index);
      setFadeIn(true);
    }, 300);
  };

  return (
    <Section
      id="ZeroTrust"
      title="Combine the Security Layers to Fit Your Goals"
    >
      <div className="container mt-3 mb-5">
        <Row>
          <Col md={3} className="mb-2 mb-lg-0">
            <Card
              className={`content-tab mb-2 ${activeTab === 0 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(0)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Continuous Authentication</Card.Body>
            </Card>
            <Card
              className={`content-tab mb-2 ${activeTab === 1 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(1)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Remote Desktop Detection</Card.Body>
            </Card>
            <Card
              className={`content-tab ${activeTab === 2 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(2)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Captcha and Bot Detection</Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Fade in={fadeIn}>
              <div>
                {activeTab === 0 && (
                  <Card>
                    <Card.Body>
                      <h3 className="mb-lg-5 mb-3">
                        Prevent account takeovers on desktop, web & mobile
                      </h3>
                      <div className="d-lg-flex">
                        <div className="w-100 w-lg-50">
                          <iframe
                            src="https://player.vimeo.com/video/1006240232"
                            title="grabxy"
                            width="440"
                            height="250"
                            frameborder="0"
                            className="video"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                          >
                          </iframe>
                        </div>
                        <div className="w-100 w-lg-50">
                          <p>
                            Our continuous authentication solution has 3
                            different modules: cursor biometrics, typing
                            biometrics, and device fingerprinting. These
                            modules can be deployed separately or together.
                            Graboxy can be easily combined with existing
                            security systems.
                          </p>
                          <p>
                            Graboxy's machine learning algorithm monitors the
                            user's behavior in real time and creates unique
                            biometric profiles. It can also identify and
                            validate devices through advanced device
                            fingerprinting techniques.
                          </p>
                          <div className="d-flex flex-row-reverse card-btn">
                            <a
                              href="/solutions/continuous-authentication"
                              className="btn"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
                {activeTab === 1 && (
                  <Card>
                    <Card.Body>
                      <h3 className="mb-lg-5 mb-3">
                        Stop tech support, AnyDesk or other remote access scams
                        in 5 secs
                      </h3>
                      <div className="d-lg-flex">
                      <div className="w-100 w-lg-50">
                          <iframe
                            title="Graboxy"
                            src="https://player.vimeo.com/video/952305360?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            allow="autoplay; fullscreen; picture-in-picture"
                            className="video"
                            allowFullScreen
                          />
                        </div>
                        <div className="w-100 w-lg-50 mt-3 mt-lg-0 d-flex flex-column align-items-end justify-content-between">
                          <p>
                            There are some glitches in the cursor movement data
                            during remote desktop sessions that go unnoticed
                            by both human observers and security systems, but
                            they can be swiftly detected by our patented
                            AI-driven Remote Access Detection solution.
                          </p>
                          <a
                            href="/solutions/device-fingerprinting"
                            className="btn mb-2 card-btn"
                          >
                            Learn more
                          </a>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
                {activeTab === 2 && (
                  <Card>
                    <Card.Body>
                      <h3 className="mb-lg-5 mb-3">
                        Frictionless detection of bots and scraping on your
                        website
                      </h3>
                      <div className="row mt-4">
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={frictionless}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Frictionless Detection</h3>
                            <p>
                              Based on device fingerprinting,
                              and movement & behavior analysis
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={gui}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Embeddable<br/>GUI Element</h3>
                            <p>
                              Such as tick boxes, which are very
                              easy and quick to solve for humans
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={maze}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Brandable<br/>Maze Captcha</h3>
                            <p>
                              Provides challenge-response
                              capability when critical security is needed
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 text-center">
                        <a
                          href="/solutions/device-fingerprinting"
                          className="btn card-btn"
                        >
                          Learn more
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <Link
            to="Contact"
            className="btn"
            spy
            smooth
          >
            Book a Demo
            {' '}
            <img src={play} alt="play" />
          </Link>
        </div>
      </div>
    </Section>
  )
};

export default ZeroTrust;
