import { Link } from 'react-scroll';
import Section from '../Section';
import styles from './Benefits.module.scss';

import timer from './gifs/timer.gif';
import gdpr from './gifs/gdpr.gif';
import ux from './gifs/ux.gif';
import reach from './gifs/reach.gif';
import cloud from './gifs/cloud.gif';
import setting from './gifs/setting.gif';
import combine from './gifs/combine.gif';
import defend from './gifs/defend.gif';

const benefitItems = [
  {
    img: defend,
    text: 'Defends against the most common consumer cyber threats',
  }, {
    img: timer,
    text: 'Works out of the box, more features enabled as AI models are trained',
  }, {
    img: ux,
    text: 'Prioritizes user experience with invisible continuous and \
      transactional authentication',
  }, {
    img: reach,
    text: 'Helps to reach your Zero Trust security goals by continuously \
      verifying user identities and devices',
  }, {
    img: cloud,
    text: 'Uses the most advanced, user interaction-based biometric AI \
      technology',
  }, {
    img: setting,
    text: 'Can be combined with 3rd party MFA, FIDO, endpoint security, and PAM \
      solutions',
  }, {
    img: combine,
    text: 'On-prem or\ncloud deployment',
  }, {
    img: gdpr,
    text: 'Compliant with HIPAA,\nSOC2, and NIS2',
  },
];

const Benefits = () => (
  <Section
    id="Benefits"
    className={styles.Benefits}
  >
    <div className="container">
      <div className="row">
        <div className="col-12 text-center mb-5">
            <h1>
              Secure User Accounts With Ultimate Protection
            </h1>
        </div>
        {benefitItems.map((item, idx) => (
          <div className="col-xl-3 col-lg-6 col-12" key={`benefit-${idx}`}>
            <div className={styles.Box}>
              <div>
                <div className={styles.BoxImage}>
                  <img src={item.img} width={100} />
                </div>
                <p>
                  {item.text}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Section>
);

export default Benefits;
