import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import PostCardImg from '../PostCardImg';
import Badges from '../Badges';
import styles from './PostCard.module.scss';

const PostCard = ({
  post: {
    featuredImage,
    title,
    date,
    author,
    excerpt,
    link,
    tags,
  },
  className,
}) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  return (
    <div className={`my-3 col-12 ${className}`}>
      <div className={styles.PostCard} onClick={onClick} role="link">
        <PostCardImg post={{ featuredImage, link }} />

        <div className="card-body">
          <h3 className={styles.CardTitle}>
            <Link
              to={link}
              dangerouslySetInnerHTML={{ __html: title.rendered }}
            />
          </h3>

          <div
            className={styles.CardText}
            dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
          />
        </div>

        <div className="p-3">
          <Link to={link} className={styles.ReadMore}>Read more</Link>
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({}),
  className: PropTypes.string,
};

PostCard.defaultProps = {
  post: {
    link: '#',
    title: { rendered: '' },
    date: '',
    author: { name: 'Admin' },
    excerpt: { rendered: '' },
    featuredImage: undefined,
    tags: [],
  },
  className: 'col-lg-4',
};

export default PostCard;
