import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-bootstrap';
import ScrollLink from '../ScrollLink';

import logoPath from '../../images/logo.svg';
import styles from './Navbar.module.scss';
import phone from './phone.svg';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const className = [
    'navbar fixed-top navbar-expand-lg navbar-dark',
    styles.Navbar,
    scrolled ? styles.scrolled : ''
  ].join(' ');

  const navToggler = [
    'd-block d-lg-none',
    styles.navToggler,
  ].join(' ');

  const onlyDesktop = [
    'd-none d-lg-block',
    styles.onlyDesktop,
  ].join(' ');

  const onlyMobile = [
    'd-block d-lg-none',
    styles.onlyMobile,
  ].join(' ');

  return (
    <nav className={className}>
      <div className="container">
        <NavLink
          href="/"
          className={styles.Logo}
        >
          <img
            src={logoPath}
            alt="Graboxy Navbar Logo"
            className={scrolled ? styles.logoSmall : ''}
            style={{ marginBottom: '-5px', width: scrolled ? '180px' : '234px' }}
          />
        </NavLink>

        <NavLink href="/" className={onlyDesktop}>
          <span className={styles.menuBtn}>Home</span>
        </NavLink>

        <div className={onlyDesktop}>
          <div className={styles.Dropdown}>
            <ScrollLink to="/" scrollTo="ZeroTrustSection" smooth>
              <span className={styles.menuBtn}>Solutions</span>
            </ScrollLink>
            <ul>
              <li>
                <NavLink href="/solutions/continuous-authentication">
                  Continuous Authentication
                </NavLink>
                <NavLink href="/solutions/multi-factor-authentication">
                  Silent 2FA
                </NavLink>
                <NavLink href="/solutions/device-fingerprinting">
                  Device Fingerprinting
                </NavLink>
                <NavLink href="/solutions/remote-desktop-detection">
                  Remote Desktop Detection
                </NavLink>
                <NavLink href="/solutions/captcha-bot-detection">
                  CAPTCHA & Bot Detection
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <NavLink href="/technology" className={onlyDesktop}>
          <span className={styles.menuBtn}>Technology</span>
        </NavLink>

        <NavLink href="/blog" target="_blank" className={onlyDesktop}>
          <span className={styles.menuBtn}>Blog</span>
        </NavLink>

        <NavLink href="/our-team" className={onlyDesktop}>
          <span className={styles.menuBtn}>Team</span>
        </NavLink>

        <button
          className={navToggler}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mt-3 mb-4 my-lg-0">
            <li className="nav-item">
              <ScrollLink
                to="/"
                scrollTo="ZeroTrustSection"
                smooth
                style={{
                  color: '#f8e3ff', fontSize: 'calc(1.3rem + 0.6vw)',
                  textTransform: 'none', fontWeight: '400'
                }}
                className={onlyMobile}
              >
                Solutions
              </ScrollLink>
              <ul>
                <li>
                  <NavLink
                    href="/solutions/continuous-authentication"
                    className={onlyMobile}
                  >
                    Continuous Authentication
                  </NavLink>
                  <NavLink
                    href="/solutions/multi-factor-authentication"
                    className={onlyMobile}
                  >
                    Silent 2FA
                  </NavLink>
                  <NavLink
                    href="/solutions/device-fingerprinting"
                    className={onlyMobile}
                  >
                    Device Fingerprinting
                  </NavLink>
                  <NavLink
                    href="/solutions/remote-desktop-detection"
                    className={onlyMobile}
                  >
                    Remote Desktop Detection
                  </NavLink>
                  <NavLink
                    href="/solutions/captcha-bot-detection"
                    className={onlyMobile}
                  >
                    CAPTCHA & Bot Detection
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                href="/technology"
                className={onlyMobile}
                style={{
                  color: '#f8e3ff', fontSize: 'calc(1.3rem + 0.6vw)',
                  textTransform: 'none', fontWeight: '400'
                }}
              >
                Technology
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                href="/blog"
                target="_blank"
                className={onlyMobile}
                style={{
                  color: '#f8e3ff', fontSize: 'calc(1.3rem + 0.6vw)',
                  textTransform: 'none', fontWeight: '400'
                }}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                href="/our-team"
                className={onlyMobile}
                style={{
                  color: '#f8e3ff', fontSize: 'calc(1.3rem + 0.6vw)',
                  textTransform: 'none', fontWeight: '400'
                }}
              >
                Team
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                to="Contact"
                className="btn"
                style={{ padding: '10px 30px' }}
                spy
                smooth
              >
                Contact Us
                {' '}
                <img src={phone} alt="phone" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
