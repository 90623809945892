import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import styles from './CaptchaBotDetection.module.scss';

import chart2 from '../components/icons/chart47.svg';
import fifty from '../components/icons/fifty.svg';
import mazelogo from '../components/icons/maze-logo.svg';

import defend from '../components/gifs/defend.gif';
import setting from '../components/gifs/setting.gif';
import gdpr from '../components/gifs/gdpr.gif';
import botnet from '../components/gifs/botnet.gif';
import frictionless from '../components/gifs/frictionless.gif';
import gui from '../components/gifs/gui.gif';
import maze from '../components/gifs/maze.gif';

const CaptchaBotDetection = () => {
  return (
    <AppLayout>
      <div className={styles.CaptchaBotDetection}>
        <Section>
          <div className={styles.GradientBg}>
            <div className={styles.Hero}>
              <div className="container">
                <div className="row pb-5">
                  <div className="col-lg-6 d-flex flex-column
                    justify-content-center align-items-start"
                  >
                    <h1 className={styles.Title}>CAPTCHA & Bot Detection</h1>
                    <h3 className="mt-3">
                      Choose from our solutions to detect bots and scraping on
                      your website
                    </h3>
                    <Link to="Contact" className="btn mt-4" spy smooth>
                      Request Demo
                    </Link>
                  </div>
                  <div className="col-lg-4 offset-lg-2">
                    <img
                      src={mazelogo}
                      className={styles.MazeImg}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center mb-4">
                    <h2>Choose your CAPTCHA & Bot Detection Solutions</h2>
                  </div>
                  <div className="col-lg-4 text-center mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={frictionless} height={120} className="mb-4" />
                      <h3>Frictionless Detection</h3>
                      <p>
                        Based on device fingerprinting,
                        and movement & behavior analysis
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 text-center mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={gui} height={120} className="mb-4" />
                      <h3>Embeddable GUI Element</h3>
                      <p>
                        Such as tick boxes, which are very
                        easy and quick to solve for humans
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 text-center mb-4 mb-lg-0">
                    <div className={styles.Box}>
                      <img src={maze} height={120} className="mb-4" />
                      <h3>Brandable MAZE CAPTCHA</h3>
                      <p>
                        Provides challenge-response
                        capability when critical security is needed
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-lg-5 pb-5">
                  <div className="col-12 pt-3 text-center">
                    <h4>
                      Graboxy CAPTCHA & Bot Detection is part of the Graboxy AI
                      Cybersecurity Platform.
                    </h4>
                    <h4>
                      Learn more about the other features such as
                      {' '}
                      <br />
                      {' '}
                      <a
                        href="/solutions/remote-desktop-detection"
                        className="mainlink"
                      >
                        Remote Desktop Detection
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/continuous-authentication"
                        className="mainlink"
                      >
                        Continuous Authentication
                      </a>
                      ,
                      {' '}
                      <a
                        href="/solutions/device-fingerprinting"
                        className="mainlink"
                      >
                        Device Fingerprinting
                      </a>
                      ,
                      {' '}
                      and
                      {' '}
                      <a
                        href="/solutions/multi-factor-authentication"
                        className="mainlink"
                      >
                        Silent 2FA
                      </a>
                      .
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Awards}>
          <Awards />
        </div>
        <Section>
          <div className="container">
            <div className="row text-center">
              <div className="col-12 mb-4">
                <iframe
                  width="720"
                  height="365"
                  style={{ maxWidth: '100%' }}
                  src="https://www.youtube.com/embed/Hog8cVGuQY0?si=JDuLABMtwlYPMJ_J"
                  frameborder="0"
                  allow="autoplay;"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="row mt-lg-5">
              <div className="col-lg-12 text-center mb-4">
                <Link to="Contact" className="btn" spy smooth>
                  Request Demo
                </Link>
              </div>
              <div className="col-12 text-center">
                <h4>
                  Free for up to 1 million monthly requests.
                </h4>
              </div>
            </div>
            <div className="row mt-lg-5">
              <div className="col-12 text-center">
                <p>
                  Replace fire hydrants and traffic lights with a CAPTCHA that
                  uses motion analysis to verify humans. Users just need to
                  solve a simple maze to pass the challenge.
                </p>
                <p>
                  You may customize the maze CAPTCHA with your preferred colors
                  and brand elements so that it blends into your website.
                  Initiate user verification or block the session based on our
                  evaluation. Frustrate bots, not your users.
                </p>
              </div>
              <div className="col-12 text-center mt-5">
                <a
                  href="/solutions/captcha-bot-detection/integration"
                  className="btn"
                >
                  Integration User Guide
                </a>
                <p className="mt-4">
                  Insert the Graboxy CAPTCHA JS code in your website with our
                  simple step-by-step integration manual.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={defend} height={120} className="mb-4" />
                  <h4>
                    Secure
                  </h4>
                  <p>
                    Our CAPTCHA doesn’t have any public domain solutions for
                    bots and scrapers unlike reCAPTCHA and other widespread
                    ones.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={setting} height={120} className="mb-4" />
                  <h4>
                    Simple integration
                  </h4>
                  <p>
                    Even when replacing or combining with alternative CAPTCHAs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={gdpr} height={120} className="mb-4" />
                  <h4>
                    Privacy-centric
                  </h4>
                  <p>
                    Our CAPTCHA solution does not collect any personal
                    information or sensitive data about your users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="Did You Know?"
          className={styles.DidYouKnow}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-5">
                <h4>
                  Attacks against your website are mostly automated. Unexpected
                  peaks in traffic are usually caused by bot attacks. You can
                  keep unwanted traffic from your website by implementing a
                  simple human vs. bot detection.
                </h4>
              </div>
              <div className="col-lg-1 text-center"></div>
              <div className="col-lg-4 text-center mb-4 mb-lg-0">
                <div
                  className={styles.Box}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <img src={chart2} height={120} className="mb-4" />
                  <p>
                    47.4% of all internet traffic came from bots.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 text-center"></div>
              <div className="col-lg-4 text-center">
                <div
                  className={styles.Box}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <img src={fifty} width={120} />
                    <img src={botnet} width={145} className="mb-4" />
                  </div>
                  <p>
                    50% of “users” that pass reCAPTCHAs are actually bots.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 text-center"></div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <Contact />
      </div>
    </AppLayout>
  );
};

export default CaptchaBotDetection;
