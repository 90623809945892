import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

const HexaLink = ({
  to, className, children, onOver, onOut,
}) => {
  if (to.indexOf('http') === 0) {
    return (
      <a
        href={to}
        className={className}
        target="_blank"
        rel="noreferrer"
        onMouseOver={onOver}
        onMouseOut={onOut}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={className}
      onMouseOver={onOver}
      onMouseOut={onOut}
    >
      {children}
    </Link>
  );
};

HexaLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onOver: PropTypes.func.isRequired,
  onOut: PropTypes.func.isRequired,
};

HexaLink.defaultProps = {
  className: undefined,
};

export default HexaLink;
