import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from 'bootstrap';

import styles from './CopyLinkButton.module.scss';

const CopyLinkButton = () => {
  const [popover, setPopover] = useState();
  const ref = useRef();

  const copy = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);

    if (popover) {
      popover.show();

      setTimeout(() => {
        popover.hide();
      }, 1000);
    }
  }, [popover]);

  useEffect(() => {
    if (ref.current) {
      setPopover(new Popover(ref.current, {
        trigger: 'focus',
        customClass: styles.Popover,
      }));
    }
  }, [ref, setPopover]);

  useEffect(() => {
    if (popover) {
      popover.hide();
    }
  }, []);

  return (
    <span
      ref={ref}
      role="button"
      onClick={copy}
      className={styles.Stack}
      data-bs-placement="left"
      data-bs-toggle="popover"
      title="Copied!"
    >
      <span className={styles.Circle} />
      <i className={styles.Link} />
    </span>
  );
};

export default CopyLinkButton;
