import React from 'react';

import {
  Form,
  TextInput,
  SubmitButton,
} from 'custom-form-client/react';

import Seo from '../../components/Seo';
import AppHeader from '../../components/AppHeader';
import BaseLayout from '../../layouts/BaseLayout';

import styles from './ZeroTrustEbook.module.scss';

import {
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../../constants';

import { downloadResource } from '../../../utils';

const ZeroTrustEbook = () => (
  <BaseLayout>
    <Seo />
    <AppHeader />
    <main className={styles.Ebook}>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 text-center">
            <h2>Free Graboxy Ebook</h2>
            <h3>
              Zero Trust Strategy - Everything You Should Know About the Zero
              Trust Model
            </h3>
            <h4>Share your email and get the full Graboxy Ebook for free.</h4>
          </div>

          <div className="col-12 mx-auto text-center" style={{ maxWidth: 576 }}>
            <Form
              id="ebookForm"
              className={styles.EbookForm}
              type="email"
              action={process.env.REACT_APP_CUSTOM_FORM_URL}
              responseMessage="Thank you! The download should start in a second."
              captchaType="graboxyCaptcha"
              captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
              captchaOptions={{
                urlBase: CAPTCHA_URL_BASE,
                apiKey: GRABOXY_API_KEY,
                trapUrl: TRAP_SERVER_URL,
              }}
              afterSubmitCallback={() => {
                downloadResource(
                  process.env.REACT_APP_ZERO_TRUST_EBOOK_URL,
                  'zero-trust-ebook.pdf',
                )
              }}
            >
              <TextInput
                className={styles.TextInput}
                id="ebook-email"
                name="email"
                type="email"
                placeholder="Email"
                required
              />

              <SubmitButton className={styles.GetTheEbook}>
                Get the Ebook
              </SubmitButton>
            </Form>
          </div>
        </div>
      </div>
    </main>
  </BaseLayout>
);

export default ZeroTrustEbook;
