import React from 'react';

import BackToLink from '../../app/components/BackToLink';
import BlogLayout from '../layouts/BlogLayout';
import Post from '../components/Post';
import TagsCloud from '../components/TagsCloud';
import LatestPosts from '../components/LatestPosts';

const PostPage = () => (
  <BlogLayout>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <BackToLink label="Back to the blog" to="/blog" className="mb-3" />
        </div>

        <div className="col-12 col-lg-8">
          <Post />
        </div>

        <div className="col-12 col-lg-4">
          <TagsCloud />
          <LatestPosts />
        </div>
      </div>
    </div>
  </BlogLayout>
);

export default PostPage;
