import AppLayout from '../../layouts/AppLayout';
import Section from '../../components/Section';
import ZeroTrust from '../../components/ZeroTrust';
import Awards from '../../components/Awards';
import Contact from '../../components/Contact';

import styles from './UseCases.module.scss';

const usecaseItems = [
  {
    title: 'Remote Access Scam Prevention',
    text: 'Graboxy continuously monitors user interactions and leverages AI to \
    analyze data transmitted via the user\'s web browser. It is able to detect \
    remote access sessions in real time, which is almost always a sign of a \
    scam attempt in online banking and payment applications. Graboxy is ready \
    to spot remote access sessions without model training, so no calibration \
    is required.',
  }, {
    title: 'Invisible 2FA & Transaction Approval',
    text: 'Graboxy lets financial institutions and payment providers use our \
    biometric confidence score as a “silent 2FA” for approving transactions. \
    We invisibly authenticate users in the background while they are engaging \
    with the banking or payment application. No need to send costly SMS codes \
    or for the user to switch between devices and apps. Provides completely \
    frictionless user enrolment and transaction authorization.',
  }, {
    title: 'Online Account Takeover Prevention',
    text: 'Prevent online account takeover frauds and identity theft with \
    Graboxy. Our AI-based cybersecurity solution gathers cursor movement data \
    from websites through browsers to create identity profiles. If the \
    real-time cursor movement analysis shows a divergence from the user’s \
    biometric profile, Graboxy flags the fraudulent user accessing the \
    account. Flagged users can be locked out or re-verified using traditional \
    MFA.',
  }, {
    title: 'Workforce Authentication',
    text: 'Protect your organization from social engineering and corporate \
    account takeover frauds with continuous biometric authentication. Graboxy \
    gathers anonymous cursor movement data directly from the user’s desktop to \
    create identity profiles. Our AI continuously monitors cursor movement \
    characteristics and reports suspicious behavior.',
  }, {
    title: 'Website Spoofing or Mirror Phishing',
    text: 'Graboxy authenticates users by analyzing cursor movement dynamics, \
    a form of behavioral biometrics that cannot be stolen or replicated. This \
    presents significant obstacles for attackers accessing sensitive data, \
    even with the proper credentials. Graboxy enhances your protection against \
    spoofed websites or phishing attempts by detecting and addressing \
    irregularities in user behavior.',
  }, {
    title: 'Privileged Access Management',
    text: 'Graboxy Sentinel aids companies in PAM by providing a robust and \
    continuous authentication solution that leverages movement biometrics. \
    This not only enhances security but also aligns with modern cybersecurity \
    strategies, ensuring a user-friendly experience while safeguarding against \
    unauthorized access and account takeover threats.',
  }, {
    title: 'Illegal Account & Device Sharing',
    text: 'Graboxy employs movement biometrics for continuous user \
    authentication, addressing device and account sharing. It ensures the \
    integrity of user accounts, reduces unauthorized access risk, and \
    safeguards against financial losses.  Graboxy enhances cybersecurity, \
    preserves business security posture, and prevents reputational damage \
    associated with fraudulent activities.',
  }, {
    title: 'Bot Traffic Detection',
    text: 'Replace fire hydrants and traffic lights with a Captcha that uses \
    movement analysis to verify humans. Users just need to solve a simple \
    maze with the cursor to pass the challenge. The maze can be customized \
    with your brand elements. Initiate user verification or block the session \
    based on our evaluation. Frustrate bots, not your users.',
  },
];

const UseCases = () => {
  return (
    <AppLayout>
      <Section
        className={styles.UseCases}
        title="Graboxy Use Cases"
      >
        <div className="container">
          <div className="row mt-5">
            {usecaseItems.map((item, idx) => (
              <div className="col-lg-12" key={`usecase-${idx}`}>
                <div className={styles.Box}>
                  <h2>
                    {item.title}
                  </h2>
                  <p>
                    {item.text}
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation"
                    target="_blank"
                    rel="noreferrer"
                    className="btn"
                  >
                    Book a meeting
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <div className={styles.ZeroTrustContainer}>
        <ZeroTrust />
      </div>
      <Awards />
      <Contact />
    </AppLayout>
  );
};

export default UseCases;
