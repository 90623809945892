import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './PostCardImg.scss';

const PostCardImg = ({
  post: {
    featuredImage,
    link,
  }
}) => (
  <Link className="card-img" to={link}>
    {featuredImage && (
      <img
        src={featuredImage}
        className="card-img-top img-fluid"
        alt="Featured"
      />
    )}
  </Link>
);

PostCardImg.propTypes = {
  post: PropTypes.shape({}).isRequired,
};

PostCardImg.defaultProps = {
  post: {
    link: '#',
    featuredImage: undefined,
  },
};

export default PostCardImg;
