import { Link } from 'react-router-dom';
import AppLayout from '../../layouts/AppLayout';
import Section from '../../components/Section';
import Contact from '../../components/Contact';

import styles from './Technology.module.scss';

import crossDomainKnowledge from '../../images/cross-domain-knowledge.svg';
import dataPreprocessing from '../../images/data-preprocessing.svg';
import extractedFeatures from '../../images/extracted-features.svg';
import featureScreening from '../../images/feature-screening.svg';
import predictionModels from '../../images/prediction-models.svg';
import typesOfSensors from '../../images/types-of-sensors.svg';
import nounScale from '../../images/noun-scale.svg';

import handGestures from '../../images/movement_analysis_hand_gestures.svg';
import fineMotor from '../../images/movement_analysis_fine_motor.svg';
import handWriting from '../../images/movement_analysis_handwriting.svg';
import eyeMovement from '../../images/movement_analysis_eyemovement.svg';
import walkingBalance from '../../images/movement_analysis_walking_balance.svg';
import heartRate from '../../images/movement_analysis_heart_rate.svg';
import computerMouse from '../../images/movement_analysis_computermouse.svg';
import touchpadScreen from '../../images/movement_analysis_touchpad_screen.svg';
import keyboard from '../../images/movement_analysis_keyboard.svg';
import wearableFitness from '../../images/movement_analysis_wearable_fitness.svg';
import smartphone from '../../images/movement_analysis_smartphone_gyroscope.svg';
import webcam from '../../images/movement_analysis_webcam.svg';

const Technology = () => {
  return (
    <AppLayout>
      <Section
        className={styles.Technology}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Discover what's under the hood</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>
                Hand gestures, eye and body movements often reveal more
                information than spoken words. Human movement is incredibly
                complex and its research is still in its early stages. To help
                solve movement analysis challenges, we developed a programming
                language that describes human motion, and a toolchain that can
                classify movement samples. We can extract and process movement
                data gathered with a wide range of devices.
              </p>
              <p>
                Check out our
                {' '}
                <Link to="/blog" target="_blank" className="mainlink">
                  blog
                </Link>
                {' '}
                to learn more.
              </p>
            </div>
          </div>
          <div className="row mb-lg-5 pb-lg-5">
            <div className="
              col-12 d-flex justify-content-between pt-5 mt-lg-5
              mb-lg-5 pb-lg-5
            ">
              <div className={styles.HexaContainer}>
                <div className={styles.Hexa}>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={handGestures} alt="" />
                      </div>
                      <p>Hand gestures</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={fineMotor} alt="" />
                      </div>
                      <p>Fine Motor Movement</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={handWriting} alt="" />
                      </div>
                      <p>Handwriting</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={eyeMovement} alt="" />
                      </div>
                      <p>Eye Movement</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={walkingBalance} alt="" />
                      </div>
                      <p>Walking &amp; Balance</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={heartRate} alt="" />
                      </div>
                      <p>Heart Rate &amp; Breathing</p>
                    </div>
                  </div>
                  <div className={styles.HexaInner}>
                    <h3>Types of<br />Human<br />Movement</h3>
                  </div>
                </div>
                <div className={styles.Hexa}>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={computerMouse} alt="" />
                      </div>
                      <p>Computer Mouse</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={touchpadScreen} alt="" />
                      </div>
                      <p>Touchpad &amp; Touchsreen</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={keyboard} alt="" />
                      </div>
                      <p>Keyboard &amp; Digital Pen</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={webcam} alt="" />
                      </div>
                      <p>Webcam &amp; Eye Camera</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={smartphone} alt="" />
                      </div>
                      <p>Phone GPS &amp; Gyroscope</p>
                    </div>
                  </div>
                  <div className={styles.HexaItem}>
                    <div className={styles.HexaBg}>
                      <div className={styles.HexaImg}>
                        <img src={wearableFitness} alt="" />
                      </div>
                      <p>Wearable Sensor</p>
                    </div>
                  </div>
                  <div className={styles.HexaInner}>
                    <h3>Movement<br />Capturing<br />Devices</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span><img src={typesOfSensors} /></span>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Types of Sensors</h3>
                  <p>
                    Fuelled by our passion for addressing various challenges
                    involving movement analysis, we are constantly exploring an
                    ever widening range of sensor types without imposing
                    limitations. Our expertise spans the entirety of motion
                    recording technology, including both pre-built and
                    personalized solutions. To mention a few examples, we have
                    used tablet-and-pen configurations for capturing signatures;
                    computer mice for monitoring user activity in continuous
                    authentication solutions; heart rate sensors, eye trackers,
                    wearable IMUs, and marker-based/markerless motion capture
                    solutions for recording motion data in digital health
                    applications.
                  </p>
                  <p>
                    We take into account each project's distinct requirements
                    and choose the most appropriate sensor solution that
                    balances ease of use with maximized data quality.
                  </p>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span><img src={dataPreprocessing} /></span>
                  <a
                    href="https://ieeexplore.ieee.org/document/8897249"
                    className={styles.OnlyDesktop}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Data Preprocessing</h3>
                  <p>
                    Regardless of the sensor type used to capture motion data,
                    it's essential to recognize that any measurement is
                    inherently inaccurate. The nature of the noise present in
                    the data provided rarely aligns with the manufacturers’
                    technical specification. This issue could stem from the
                    device's inherent limitations or deliberate modifications
                    in the firmware that either smooth or distort the actual
                    motion.
                  </p>
                  <p>
                    For example, when recording data with a mouse, angle
                    snapping or mouse acceleration is a standard noise added
                    by the computer. Additionally, typical artistic users
                    prefer seeing smooth lines on the tablet screen, thereby
                    necessitating smoothing. Despite these challenges, most
                    motion analysis experts rely on standard data cleaning and
                    smoothing algorithms to manage inaccurate and noisy raw
                    data, but we believe that these standard approaches fall
                    short. Although standard filters might display a smoother
                    line on the screen, they may further distort the
                    acceleration data of the cursor.
                  </p>
                  <p>
                    As such, we employ a reverse engineering approach,
                    thoroughly examining each device, understanding the
                    background and figuring out how to carry out device-specific
                    data preprocessing in order to recreate the original
                    movement as accurately as possible.
                  </p>
                  <a
                    href="https://ieeexplore.ieee.org/document/8897249"
                    className={styles.OnlyMobile}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span><img src={extractedFeatures} /></span>
                  <a
                    href="https://www.cursorinsight.com/post/132/discovering-the-periodic-table-of-movement-analysis"
                    className={styles.OnlyDesktop}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Extracted Features</h3>
                  <p>
                    Our analytical stack employs a proprietary universal feature
                    space to represent multidimensional time series datasets.
                    This feature space was initially created using the technical
                    meta-language of handwriting analysis, but has since been
                    adapted over the past 15 years to suit other domains as
                    well. It relies heavily on the dynamical properties of
                    movements to provide a detailed representation of the motor
                    program patterns that underlie the data. The extracted
                    motion features have been effectively used in various
                    fields, such as signature verification, continuous mouse
                    movement authentication for cybersecurity purposes,
                    personality trait prediction, and medical applications.
                  </p>
                  <p>
                    Leveraging this feature space provides us with a significant
                    advantage over the prevalent use of standard physical
                    features that lack deeper insight into the captured motion
                    data.
                  </p>
                  <a
                    href="https://www.cursorinsight.com/post/132/discovering-the-periodic-table-of-movement-analysis"
                    className={styles.OnlyMobile}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span><img src={featureScreening} /></span>
                  <a
                    href="https://www.cursorinsight.com/post/629/the-biometricblender-taming-hyperparameters-for-better-feature-screening"
                    className={styles.OnlyDesktop}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Feature Screening</h3>
                  <p>
                    The selection of an optimal feature subset that effectively
                    characterizes any movement data is pivotal for building
                    efficient machine learning models. Given that our feature
                    space consists of tens of thousands of features, it is
                    critical to identify the most relevant ones for each
                    specific task.
                  </p>
                  <p>
                    By working with a carefully selected, small subset of
                    features, we can construct accurate prediction models even
                    when only a limited amount of data is available. We have
                    tried a wide range of well-known screening methods to
                    accomplish this, but none of these methods yielded the
                    desired results.
                  </p>
                  <p>
                    Consequently, we have developed our own feature screener
                    method in collaboration with the Wigner Research Centre
                    within the Hungarian Academy of Sciences, which we call
                    the Random Forest - based Multi - Round Screening
                    Method (RFMS). The Julia package that implements RFMS is
                    publicly available on GitHub. To benchmark our algorithm,
                    we used our own dataset generator called BiometricBlender
                    which is also publicly available.
                  </p>
                  <a
                    href="https://www.cursorinsight.com/post/629/the-biometricblender-taming-hyperparameters-for-better-feature-screening"
                    className={styles.OnlyMobile}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span><img src={predictionModels} /></span>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Prediction Models</h3>
                  <p>
                    Rather than relying on basic data analysis methods or
                    feeding vast amounts of data blindly into deep learning
                    models, our approach is to thoroughly comprehend the problem
                    and the data at hand, and then select the appropriate
                    solution. We are experts in various sophisticated decision
                    making tools and always choose the most suitable one for a
                    given project. Our proprietary AI toolchain enables us to
                    extract task-specific, meaningful features that capture
                    the essence of movement, resulting in more explainable
                    models that in turn often lead to new discoveries.
                  </p>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span>
                    <img src={crossDomainKnowledge} />
                  </span>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Cross-domain Knowledge</h3>
                  <p>
                    We are always keen to gain a deeper understanding
                    of human movement, and we have gathered experience in motion
                    analysis across a wide range of domains, such as:
                  </p>
                  <ul>
                    <li>Signature verification,</li>
                    <li>Cybersecurity,</li>
                    <li>Bot detection,</li>
                    <li>Continuous authentication,</li>
                    <li>Gaming,</li>
                    <li>Air traffic control,</li>
                    <li>Athletics,</li>
                    <li>Video-based walking recognition,</li>
                    <li>Personality trait prediction,</li>
                    <li>Neurological condition assessment,</li>
                    <li>Emotional state and stress level evaluation,</li>
                    <li>Biomedical signal analysis.</li>
                  </ul>
                  <p>
                    This means that we are equipped with valuable and
                    transferable skills that can be leveraged in any task
                    involving complex time series analysis.
                  </p>
                </div>
              </div>
              <div className={styles.TechnologyBox}>
                <div className={styles.TechnologyBadge}>
                  <span>
                    <img src={nounScale} />
                  </span>
                </div>
                <div className={styles.TechnologyText}>
                  <h3>Scalable Solutions</h3>
                  <p>
                    In contrast to many research-focused teams, we have the
                    experience of delivering commercial solutions that can scale
                    to meet the highest business requirements. One such example
                    is our biometric signature authentication solution, which
                    has been relied upon by major banks for numerous years.
                  </p>
                  <p>
                    Our system is capable of analyzing millions of signatures
                    per year and handling highly sensitive biometric data for
                    millions of users, while adhering to strict security,
                    GDPR and ISO standards. Our team is committed to
                    comprehending each client's unique needs and providing
                    solutions to challenges that may arise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Contact />
    </AppLayout>
  );
};

export default Technology;
