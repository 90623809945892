import React from 'react'

import AppLayout from '../../layouts/AppLayout';
import Section from '../../components/Section';
import Awards from '../../components/Awards';
import Blog from '../../../blog/components/Blog';
import Contact from '../../components/Contact';

import styles from './CaseStudy.module.scss';

import banking from '../../images/banking.png';
import piktogram from './piktogram.png'

const CaseStudy = () => {
  return (
    <AppLayout>
      <div className={styles.CaseStudy}>
        <Section
          title="Continuous Online User Authentication in Banking"
          className={styles.GradientBg}
        >
          <div className="container">
            <div className="row pb-5">
              <div className="col-12 text-center">
                <div className={styles.Intro}>
                  <div className={styles.Card}>
                    <img src={banking} alt="" width={120} />
                  </div>
                  <p><strong>Customer:</strong> Large Bank in the CEE Region</p>
                  <p>
                    <strong>Goal:</strong> Decrease the number of fraudulent
                    online banking transactions
                    <br />
                    and reduce the cost of transaction authorization
                  </p>
                  <a
                    href="https://calendly.com/tamas-cursor-insight/consultation
                    "
                    target="_blank"
                    className="btn mt-3"
                  >
                    Schedule a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section className={styles.DottedBg}>
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Challenge</h2>
                  <p>
                    By the first half of 2023, the country's annual incidence
                    of credit transfer fraud exceeded 22 million USD. In 2019,
                    this amount was below 3 million USD; by the latter part of
                    2020, it only rose to over 4.5 million USD. Additionally,
                    the cost of sending OTPs via SMS has been steadily
                    increasing.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <img src={piktogram} />
                </div>
              </div>
            </div>
            <div className={`row mb-5 ${styles.Reverse}`}>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <iframe
                    src="https://player.vimeo.com/video/694347500?h=b41ff280fe"
                    width="540"
                    height="315"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Element}>
                  <h2>Solution</h2>
                  <p>
                    Graboxy uses movement biometrics to continuously
                    authenticate users. It gathers cursor movement data from
                    the online banking platform through the browser to create
                    identity profiles. If the real-time cursor movement
                    analysis shows a divergence from the user's biometric
                    profile, Graboxy flags the suspicious user sessions.
                    Flagged users can be locked out or re-verified.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5 text-center">
              <div className="col-12">
                <a
                  href="https://calendly.com/tamas-cursor-insight/consultation"
                  target="_blank"
                  className="btn mt-3"
                >
                  Schedule a call
                </a>
              </div>
            </div>
          </div>
        </Section>
        <Section className={styles.GradientBg}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className={styles.Title}>How it works</h2>
                <div className={styles.Steps}>
                  <div className={styles.Step}>
                    <h5>Step 1</h5>
                    <p>
                      The user logs in to the banking account and carries
                      out the usual actions by moving the cursor.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <h5>Step 2</h5>
                    <p>
                      Graboxy analyzes the cursor dynamics of the user in real
                      time.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <h5>Step 3</h5>
                    <p>
                      By the time the user initiates a transaction, Graboxy
                      authenticates the user.
                    </p>
                  </div>
                  <div className={styles.Step}>
                    <p>
                      <span className={styles.Blue}>Authorized user</span>
                      <br />
                      Silent 2FA completed
                    </p>
                    <hr />
                    <p>
                      <span className={styles.Red}>Unauthorized user</span>
                      <br />
                      Request for traditional 2FA
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <div className={styles.Results}>
                  <h2>Results</h2>
                  <div className="d-md-flex justify-content-center my-5">
                    <div className={styles.ResultItem}>
                      <p>
                        Cost saving on
                        <br />
                        OTPs via SMS:
                      </p>
                      <h3>-85%</h3>
                    </div>
                    <div className={styles.ResultItem}>
                      <p>Fraud detection accuracy*:</p>
                      <h3>97%</h3>
                      <span>
                        * Where sufficient training data was available.
                      </span>
                    </div>
                    <div className={styles.ResultItem}>
                      <p>Authenticated user sessions daily:</p>
                      <h3>100K+</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Awards />
        <div className={styles.BlogSection}>
          <Blog />
        </div>
        <Contact />
      </div>
    </AppLayout>
  );
}

export default CaseStudy;
