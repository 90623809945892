import React from 'react';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import IndexPage from './app/pages/IndexPage';
import OurTeam from './app/pages/OurTeam';
import Technology from './app/pages/Technology';
import UseCases from './app/pages/UseCases';
import CaseStudy from './app/pages/CaseStudy';

// Solutions
import RemoteDesktopDetection from './app/pages/Solutions/RemoteDesktopDetection';
import ContinuousAuthentication from './app/pages/Solutions/ContinuousAuthentication';
import DeviceFingerprinting from './app/pages/Solutions/DeviceFingerprinting';
import MultiFactorAuthentication from './app/pages/Solutions/MultiFactorAuthentication';
import CaptchaBotDetection from './app/pages/Solutions/CaptchaBotDetection';
import Integration from './app/pages/Solutions/CaptchaBotDetection/Integration';

import TagRedirect from './blog/pages/TagRedirect';
import BlogPage from './blog/pages/BlogPage';
import PostPage from './blog/pages/PostPage';
import Privacy from './app/pages/privacy/Privacy';
import AtoAttackEbook from './app/pages/atoattackebook/AtoAttackEbook';
import ZeroTrustEbook from './app/pages/zerotrustebook/ZeroTrustEbook';
import BiometricEbook from './app/pages/biometricauthenticationebook/BiometricEbook';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <Route exact path="/our-team" component={OurTeam} />
      <Route exact path="/technology" component={Technology} />
      <Route exact path="/use-cases" component={UseCases} />
      <Route exact path="/case-study" component={CaseStudy} />
      <Route
        exact
        path="/solutions/remote-desktop-detection"
        component={RemoteDesktopDetection}
      />
      <Route
        exact
        path="/solutions/continuous-authentication"
        component={ContinuousAuthentication}
      />
      <Route
        exact
        path="/solutions/device-fingerprinting"
        component={DeviceFingerprinting}
      />
      <Route
        path="/solutions/multi-factor-authentication"
        component={MultiFactorAuthentication}
      />
      <Route
        exact
        path="/solutions/captcha-bot-detection"
        component={CaptchaBotDetection}
      />
      <Route
        exact
        path="/solutions/captcha-bot-detection/integration"
        component={Integration}
      />

      <Redirect exact from="/blog/tag" to="/blog/1" />

      <Route
        exact
        from="/blog/tag/:tag"
        component={TagRedirect}
      />

      <Redirect exact from="/blog" to="/blog/1" />
      <Route exact path="/blog/:page" component={BlogPage} />
      <Route exact path="/post/:postId/:slug" component={PostPage} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/atoattackebook" component={AtoAttackEbook} />
      <Route exact path="/zerotrustebook" component={ZeroTrustEbook} />
      <Route
        exact
        path="/biometricauthenticationebook"
        component={BiometricEbook}
      />
      <Redirect path="**" to="/" />
    </Switch>
  </BrowserRouter>
);

export default App;
