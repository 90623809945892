import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import useTags from '../hooks/useTags';
import { ContainerSpinner } from '../../app/components/Spinner';
import BlogLayout from '../layouts/BlogLayout';

const TagRedirect = () => {
  const { tag } = useParams();
  const { isLoading, tags } = useTags({ tag });

  if (isLoading) {
    return (
      <BlogLayout>
        <ContainerSpinner />
      </BlogLayout>
    );
  }

  if (tags[0]) {
    return <Redirect to={tags[0].redirect} />;
  }

  return <Redirect to="/blog/1" />;
};

export default TagRedirect;
