import Section from '../Section';
import Video from './Video';
import styles from './CaseStudy.module.scss';

import play from './play.svg';

const CaseStudy = () => (
  <Section
    title="Case Study"
    className={styles.CaseStudy}
  >
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div>
            <h3 className="mb-4">
              Continuous Online User Authentication in Banking
            </h3>
            <p className="mb-4">
              Goal: Decrease the number of fraudulent online banking
              transactions and reduce the cost of transaction
              authorization
            </p>
            <div className={styles.Results}>
              <div className="d-md-flex justify-content-center my-5">
                <div className={styles.ResultItem}>
                  <p>
                    Cost saving on
                    <br />
                    OTPs via SMS:
                  </p>
                  <h3>-85%</h3>
                </div>
                <div className={styles.ResultItem}>
                  <p>Fraud detection accuracy*:</p>
                  <h3>97%</h3>
                  <span>
                    * Where sufficient training data was available.
                  </span>
                </div>
                <div className={styles.ResultItem}>
                  <p>Authenticated user sessions daily:</p>
                  <h3>100K+</h3>
                </div>
              </div>
            </div>
            <a href="/case-study" className="btn">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default CaseStudy;
