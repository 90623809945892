import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Tag.module.scss';

const Tag = ({ tag }) => (
  <Link
    to={tag.link}
    className={`btn rounded-pill m-1 p-0 px-3 ${styles.Tag}`}
  >
    {tag.name}
  </Link>
);

Tag.propTypes = {
  tag: PropTypes.shape({}).isRequired,
};

export default Tag;
