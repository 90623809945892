import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ content }) => (
  <div className="row">
    <div className="col-12">
      <div className="alert alert-danger">
        {content.toString()}
      </div>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ErrorMessage;
