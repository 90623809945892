import React from 'react'
import Slider from 'react-slick';

import AppLayout from '../../layouts/AppLayout';
import Section from '../../components/Section';
import Awards from '../../components/Awards';
import Contact from '../../components/Contact';

import styles from './OurTeam.module.scss';

import team1 from '../../images/cursor_insight.jpg';
import team2 from '../../images/CI_evezes.jpg';
import team3 from '../../images/gang.jpg';
import team4 from '../../images/lounge.jpg';
import tamas from '../../images/founders-tamas.jpg';
import bence from '../../images/founders-bence.jpg';
import peter from '../../images/founders-peter.jpg';
import linkedin from '../../images/linkedin.png';
import mail from '../../images/mail.png';

const SETTINGS = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
  cssEase: 'linear',
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  touchThreshold: 100,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
};

const OurTeamPage = () => (
  <AppLayout>
    <Section
      className={styles.OurTeam}
    >
      <div className={styles.Top}>
        <div className="row">
          <div className="col-lg-6">
            <div className={styles.Intro}>
              <h2 className={styles.Title}>Our Team</h2>
              <p>
                Graboxy is an AI Cybersecurity Platform developed by Cursor
                Insight.
              </p>
              <p>
                We at Cursor Insight are experts in AI-backed human movement
                analysis.
                Having invested a combined 100+ person-years into
                developing AI prediction models, we are leaders in our field.
              </p>
              <p>
                Our award-winning machine learning technology is capable of
                identifying and classifying users by learning their unique
                movement patterns while they interact with a computer or a phone
                or appear on video.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <Slider {...SETTINGS} className="teamslider">
              <div className={styles.MainImg}>
                <img src={team2} alt="" />
              </div>
              <div className={styles.MainImg}>
                <img src={team1} alt="" />
              </div>
              <div className={styles.MainImg}>
                <img src={team3} alt="" />
              </div>
              <div className={styles.MainImg}>
                <img src={team4} alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mx-auto" style={{ maxWidth: 1120 }}>
          <div className="col-12 text-center">
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              Based in the UK and Hungary, we build
              biometric applications across banking, gaming, cybersecurity
              and healthcare.
            </p>
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              We provide services to over 2 million clients of Central
              Europe’s largest bank and we partner with leading research
              institutions and Fortune 1000 companies.
            </p>
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              We believe in finding the right tools to solve problems, rather
              than the other way around. Innovation is in our DNA and we
              contribute to the wider community with
              {' '}
              <a
                href="https://github.com/cursorinsight"
                target="_blank"
                rel="noreferrer"
              >
                open source projects
              </a>
              {' '}
              and
              {' '}
              <a
                href="https://www.youtube.com/channel/UCam7igqCxlkq9Ih0cmdECEA/playlists"
                target="_blank"
                rel="noreferrer"
              >
                meetups
              </a>.
            </p>
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              These are the values that we work along to reach our goals.
            </p>
          </div>
        </div>
        <div className="row mx-auto" style={{ maxWidth: 1120 }}>
          <div className="col-12">
            <div className={styles.TeamGoals}>
              <div className={styles.Card}>
                <div className={styles.CardHead}>
                  <h5>Act<br />{' '}Responsibly</h5>
                </div>
                <div className={styles.CardContent}>
                  <ul>
                    <li>Ownership</li>
                    <li>Proactivity</li>
                    <li>Data Protection</li>
                  </ul>
                </div>
              </div>
              <div className={styles.Card}>
                <div className={styles.CardHead}>
                  <h5>Adapt and<br />{' '}Improve</h5>
                </div>
                <div className={styles.CardContent}>
                  <ul>
                    <li>Knowledge Sharing</li>
                    <li>Innovation</li>
                    <li>Feedback</li>
                  </ul>
                </div>
              </div>
              <div className={styles.Card}>
                <div className={styles.CardHead}>
                  <h5>Respect Clients<br />{' '}and Each Other</h5>
                </div>
                <div className={styles.CardContent}>
                  <ul>
                    <li>Transparency</li>
                    <li>Enjoy the Process</li>
                    <li>No Time Wasting</li>
                  </ul>
                </div>
              </div>
              <div className={styles.Card}>
                <div className={styles.CardHead}>
                  <h5>Focus and<br />{' '}Simplify</h5>
                </div>
                <div className={styles.CardContent}>
                  <ul>
                    <li>Focus on the Problem</li>
                    <li>Take Risks</li>
                    <li>Less is More</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-auto" style={{ maxWidth: 1120 }}>
          <div className="col-12 text-center my-lg-5">
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              We take advantage of working flexible hours either remotely or
              from our first-class Budapest city centre
              {' '}
              <a
                href="https://www.thespotcowork.com/hu/budapest/"
                target="_blank"
                rel="noreferrer"
              >
                office
              </a>
              .
            </p>
            <p
              style={{ color: 'white', fontSize: '18px', textAlign: 'center' }}
            >
              <a
                href="https://www.cursorinsight.com/our-team"
                target="_blank"
                rel="noreferrer"
              >
                Check out
              </a>
              {' '}
              our open positions
            </p>
          </div>
        </div>
        <div className="row my-lg-5 mt-5">
          <div className="col-12">
            <div className={styles.Founders}>
              <h2 className={styles.Title}>Founders</h2>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className={styles.Founder}>
                    <div className={styles.FounderImage}>
                      <img src={tamas} alt="" />
                    </div>
                    <div className={styles.FounderData}>
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div className={styles.FounderName}>
                            Tamas Zelczer
                          </div>
                          <div className={styles.FounderPosition}>
                            CEO
                          </div>
                        </div>
                        <div className={
                          `col-lg-4 col-md-4 col-sm-12 col-xs-12
                          ${styles.FounderContact}`}
                        >
                          <a
                            href="https://hu.linkedin.com/in/tamaszelczer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                          <a href="mailto:tamas@cursorinsight.com">
                            <img src={mail} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className={styles.Founder}>
                    <div className={styles.FounderImage}>
                      <img src={bence} alt="" />
                    </div>
                    <div className={styles.FounderData}>
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div className={styles.FounderName}>
                            Bence Golda
                          </div>
                          <div className={styles.FounderPosition}>
                            CTO
                          </div>
                        </div>
                        <div
                          className={`
                          col-lg-4 col-md-4 col-sm-12 col-xs-12
                          ${styles.FounderContact}`}
                        >
                          <a
                            href="https://hu.linkedin.com/in/bence-golda-0b42b5b"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                          <a href="mailto:bence@cursorinsight.com">
                            <img src={mail} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className={styles.Founder}>
                    <div className={styles.FounderImage}>
                      <img src={peter} alt="" />
                    </div>
                    <div className={styles.FounderData}>
                      <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <div className={styles.FounderName}>
                            Dr. Gergely Peter Hanczar
                          </div>
                          <div className={styles.FounderPosition}>
                            Head of R&amp;D
                          </div>
                        </div>
                        <div
                          className={`
                          col-lg-4 col-md-4 col-sm-12 col-xs-12
                          ${styles.FounderContact}`}
                        >
                          <a
                            href="https://hu.linkedin.com/in/gergely-hanczar-phd-1912445a"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={linkedin} alt="" />
                          </a>
                          <a href="mailto:gergely@cursorinsight.com">
                            <img src={mail} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Awards />
      </div>
    </Section>
    <Contact />
  </AppLayout>
);

export default OurTeamPage;
