import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './Badge.module.scss';

const Badge = ({ link, name }) => (
  <Link to={link} className={styles.Badge}>
    {name}
  </Link>
);

Badge.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Badge;
