import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Pager = ({ to, value, active }) => {
  const className = ['page-item', active ? 'active disabled' : ''].join(' ');

  return (
    <li className={className}>
      <NavLink to={to} className="page-link text-nowrap">
        {value}
      </NavLink>
    </li>
  );
};

Pager.propTypes = {
  to: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
};

Pager.defaultProps = {
  active: false,
};

export default Pager;
