import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../components/Footer';

const BaseLayout = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
);

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
