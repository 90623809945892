import Section from '../Section';
import styles from './FeaturedIn.module.scss';

import fintech from './featured/featured-cee-fintech.png';
import fintech2 from './featured/featured-cee-fintech-colored.png';
import biometric from './featured/featured-biometric-update.png';
import biometric2 from './featured/featured-biometric-update-colored.png';
import portfolio from './featured/featured-portfolio.png';
import portfolio2 from './featured/featured-portfolio-colored.png';
import forbes from './featured/featured-forbes.png';
import forbes2 from './featured/featured-forbes-colored.png';
import producthunt from './featured/featured-producthunt.png';
import producthunt2 from './featured/featured-producthunt-colored.png';
import magazine from './featured/featured-magazine.png';
import magazine2 from './featured/featured-magazine-colored.png';
import telex from './featured/featured-telex.png';
import telex2 from './featured/featured-telex-colored.png';
import biometrics from './featured/featured-biometrics.png';
import biometrics2 from './featured/featured-biometrics-colored.png';

const Featured = () => (
  <Section
    className={styles.FeaturedIn}
  >
    <div className="row text-center">
      <div className="col-12 mb-5">
        <h1>Featured in</h1>
      </div>
    </div>
    <div className="row text-center">
      <div className="col-12 mb-5">
        <div className={styles.FeaturedContent}>
          <div className="d-flex flex-wrap justify-content-center">
            <div className={styles.FeaturedItem}>
              <a
                href="https://www.biometricupdate.com/202204/real-time-movement-biometrics-to-protect-against-enterprise-account-takeover"
                target="_blank"
                rel="noreferrer"
              >
                <img src={biometric} alt="biometric" />
                <img src={biometric2} alt="biometric" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a href="/downloads/ci-forbes-interview.pdf" download>
                <img src={forbes} alt="forbes" />
                <img src={forbes2} alt="forbes" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a href="/downloads/cyber-defense-magazine.pdf" download>
                <img src={magazine} alt="magazine" />
                <img src={magazine2} alt="magazine" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a href="/downloads/ci-portfolio-interview.pdf" download>
                <img src={portfolio} alt="portfolio" />
                <img src={portfolio2} alt="portfolio" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a
                href="https://www.producthunt.com/posts/graboxy-maze-captcha"
                target="_blank"
                rel="noreferrer"
              >
                <img src={producthunt} alt="producthunt" />
                <img src={producthunt2} alt="producthunt" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a
                href="http://www.cee-fintech.com/index.php/2016/11/03/cursor-movement-based-user-identification-ad-targeting-and-signature-veryfication-interview-with-tamas-zelczer-ceo-at-cursor-insight/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fintech} alt="fintech" />
                <img src={fintech2} alt="fintech" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a href="/downloads/telex.pdf" download>
                <img src={telex} alt="telex" />
                <img src={telex2} alt="telex" />
              </a>
            </div>
            <div className={styles.FeaturedItem}>
              <a
                href="https://findbiometrics.com/id-talk-podcast-cursor-insight-705249/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={biometrics} alt="biometrics" />
                <img src={biometrics2} alt="biometrics" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Featured;
