import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import styles from './PostTile.module.scss';

const PostTile = ({
  post: {
    link,
    title,
    featuredImage,
    excerpt,
  },
}) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  return (
    <div className="col-md-6 col-lg-12 mb-4">
      <div className={styles.PostTile} onClick={onClick} role="none">
        <div className="card-body">
          <h5
            className={styles.TileTitle}
            dangerouslySetInnerHTML={{ __html: title.rendered }}
          />

          <div className={styles.TileImg}>
            <img src={featuredImage} alt="Featured" />
          </div>

          <div
            className={styles.TileText}
            dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
          />
        </div>
      </div>
    </div>
  );
};

PostTile.propTypes = {
  post: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.shape({}).isRequired,
    featuredImage: PropTypes.string.isRequired,
    excerpt: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default PostTile;
