import React from 'react';

import useTags from '../../hooks/useTags';
import Spinner from '../../../app/components/Spinner';
import Badges from '../Badges';

const TagsCloud = () => {
  const { isLoading, tags } = useTags();

  return (
    <div className="row mb-4 d-none d-lg-block">
      <div className="col-12">
        <h3 style={{ color: '#ffffff' }}>
          Tags
        </h3>
      </div>

      <div className="col-12">
        {isLoading
          ? <Spinner />
          : <Badges badges={tags} />
        }
      </div>
    </div>
  );
};

export default TagsCloud;
