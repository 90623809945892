import { Link } from 'react-scroll';
import Partners from './components/Partners';

import Section from '../../components/Section';
import Hexagons from './Hexagons';
import styles from './Hero.module.scss';

import play from './play.svg';

const Hero = () => (
  <Section
    className={styles.Hero}
  >
    <div className="container">
      <div className="row pt-lg-5 pt-3 mt-3">
        <div className="col-12 text-center">
          <h1>
            AI Cybersecurity Platform
          </h1>
        </div>
        <div className="col-12 pt-3 text-center">
          <Link
            to="Contact"
            className="btn"
            spy
            smooth
          >
            Book A Demo
            {' '}
            <img src={play} />
          </Link>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-12 mb-lg-5">
          <Hexagons />
        </div>
      </div>
    </div>

    <Partners />

  </Section>
);

export default Hero;
