import React from 'react';
import PropTypes from 'prop-types';

import StepImage from './StepImage';
import Separator from './Separator';

const Step = ({
  idx, image, inverse, showSeparator, children,
}) => {
  const firstColClasses = [
    'col-12 col-lg-5 order-0 text-center',
    inverse ? 'order-lg-2' : '',
  ].join(' ');

  const lastColClasses = [
    'col-12 col-lg-5 order-1',
    inverse ? 'order-lg-0 text-lg-end' : '',
  ].join(' ');

  return (
    <div className="row justify-content-center mb-lg-0 mb-5">
      <div className={firstColClasses}>
        <StepImage imgSrc={image} imgAlt={children}/>
      </div>

      {showSeparator && (
        < Separator idx={idx} />
      )}

      <div className={lastColClasses}>
        <h4>
          Step
          {' '}
          {idx}
        </h4>

        <h2
          className="text-white fw-bold"
        >
          {children}
        </h2>
      </div>
    </div>
  );
};

Step.propTypes = {
  idx: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  showSeparator: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Step.defaultProps = {
  inverse: false,
  showSeparator: true,
};

export default Step;
