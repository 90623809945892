import React from 'react';
import PropTypes from 'prop-types';

import Badge from './Badge';

const Badges = ({ badges, limit }) => {
  const renderedBadges = badges.slice(0, limit).map((tag) => (
    <Badge key={tag.slug} link={tag.link} name={tag.name} />
  ));

  const needDots = limit < badges.length;

  return (
    <div>
      {renderedBadges}
      {needDots && <span>...</span>}
    </div>
  );
};

Badges.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  limit: PropTypes.number,
};

Badges.defaultProps = {
  badges: [],
  limit: undefined,
};

export default Badges;
