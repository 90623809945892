import React from 'react';
import PropTypes from 'prop-types';

const StepImage = ({ imgSrc, imgAlt }) => (
  <img
    src={imgSrc}
    alt={imgAlt}
    className="mb-lg-0 mb-3"
  />
);

StepImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

export default StepImage;
