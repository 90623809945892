import { useCallback, useState } from 'react';

import Text from './Text';
import Hexagon from './Hexagon';
import texts from './Texts.module.scss';
import hexagons from './Hexagons.module.scss';

const TEXTS = [
  {
    className: texts.FraudPrevention,
    children: 'Fraud Prevention',
  },
  {
    className: texts.ContinuousAuthentication,
    children: 'Continuous Authentication',
  },
  {
    className: texts.ZeroTrust,
    children: 'Zero Trust',
  },
];

const HEXAGONS = [
  {
    idx: 1,
    to: 'https://www.graboxy.com/solutions/remote-desktop-detection',
    className: hexagons.Hexagon1,
    title: 'Remote\nAccess\nScam',
  },
  {
    idx: 2,
    to: 'https://graboxy.com/solutions/continuous-authentication',
    className: hexagons.Hexagon2,
    title: 'Account Takeover Attack',
  },
  {
    idx: 3,
    to: 'https://graboxy.com/solutions/multi-factor-authentication',
    className: hexagons.Hexagon3,
    title: 'Website Spoofing or Mirror Phishing',
  },
  {
    idx: 4,
    to: 'https://graboxy.com/solutions/continuous-authentication',
    className: hexagons.Hexagon4,
    title: 'Illegal Account & Device Sharing',
  },
  {
    idx: 6,
    to: 'https://graboxy.com/solutions/multi-factor-authentication',
    className: hexagons.Hexagon5,
    title: 'Invisible 2FA & Transaction Approval',
  },
  {
    idx: 7,
    to: 'https://graboxy.com/solutions/continuous-authentication',
    className: hexagons.Hexagon6,
    title: 'Workforce Authentication',
  },
  {
    idx: 8,
    to: 'https://graboxy.com/solutions/continuous-authentication',
    className: hexagons.Hexagon7,
    title: 'Privileged Access Management',
  },
  {
    idx: 9,
    to: 'https://graboxy.com/solutions/captcha-bot-detection',
    className: hexagons.Hexagon8,
    title: 'Bot Traffic Detection',
  },
];

const Hexagons = () => {
  const [idx, setIdx] = useState(0);

  const onMouse = useCallback((idx) => {
    setIdx(idx);
  }, [setIdx]);

  const renderedHexagons = HEXAGONS.map((hexagon) => (
    <Hexagon key={hexagon.idx} {...hexagon} onMouse={onMouse} />
  ));

  const renderedTexts = TEXTS.map(({ className, children }, i) => {
    const classes = [
      className,
      (0 < idx && idx <= 4) && texts.FraudPreventionHover,
      (6 <= idx) && texts.ContinuousAuthenticationHover,
      (6 <= idx) && texts.ZeroTrustHover,
    ].join(' ');

    return (
      <Text key={i} className={classes}>
        {children}
      </Text>
    );
  });

  return (
    <div className={hexagons.Hexagons}>
      {renderedTexts}
      {renderedHexagons}
    </div>
  );
};

export default Hexagons;
