import formatDate from './formatDate';
import formatTags from './formatTags';

export function getAuthor(embedded) {
  const author = embedded['author'];

  if (!author || !author[0]) {
    return undefined;
  }

  return {
    id: author[0],
    name: author[0].name,
    slug: author[0].slug,
    avatar: author[0].acf ? author[0].acf.avatar : undefined,
  };
}

export function getFeaturedImage(embedded) {
  const featureMedia = embedded['wp:featuredmedia'];

  if (!featureMedia || !featureMedia[0]) {
    return undefined;
  }

  return featureMedia[0].source_url;
}

export function getTags(embedded) {
  const term = embedded['wp:term'];

  if (!term || !term[1]) {
    return [];
  }

  return formatTags(term[1]);
}

export default function formatPosts(posts) {
  return posts.map(
    ({ id, slug, title, date, content, excerpt, _embedded, ...props }) => ({
      id, link: `/post/${id}/${slug}`, title, content, excerpt,
      date: formatDate(date),
      author: getAuthor(_embedded),
      featuredImage: getFeaturedImage(_embedded),
      tags: getTags(_embedded),
      embedded: _embedded,
      props,
    })
  );
}
