import React, { useEffect, useRef } from 'react';
import { Link as MenuLink } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import ScrollLink from '../ScrollLink';

import Logo from '../../../app/components/Logo';
import styles from './AppHeader.module.scss';
import sentinelLogo from './logos/logo-graboxy-sentinel.svg';
import twofaLogo from './logos/logo-graboxy-2fa.svg';
import anticheatLogo from './logos/logo-graboxy-anticheat.svg';
import captchaLogo from './logos/logo-graboxy-captcha.svg';
import ciLogo from './logos/logo-cursor-insight.svg';

const className = [
  'navbar fixed-top navbar-expand-lg',
  styles.Navbar,
].join(' ');

const navToggler = [
  'd-block d-lg-none',
  styles.navToggler,
].join(' ');

const onlyDesktop = [
  'd-none d-lg-block',
  styles.onlyDesktop,
].join(' ');

const onlyMobile = [
  'd-block d-lg-none',
  styles.onlyMobile,
].join(' ');

const productMenu = [
  'navbar-nav d-block d-lg-none',
  styles.ProductMenu,
].join(' ');

const AppHeader = () => {
  const navRef = useRef();

  useEffect(() => {
    document.onscroll = () => {
      if (!navRef || !navRef.current) return;

      const top = document.body.scrollTop + document.documentElement.scrollTop;

      if (top > 0) {
        navRef.current.classList.add(styles.NavbarBg);
        navRef.current.classList.remove(styles.NavbarNoBg);
      } else {
        navRef.current.classList.add(styles.NavbarNoBg);
        navRef.current.classList.remove(styles.NavbarBg);
      }
    };

    return () => {
      document.onscroll = undefined;
    };
  }, [navRef]);

  return (
    <nav ref={navRef} className={className}>
      <div className="container">
        <MenuLink
          to="/"
          className="navbar-brand"
          style={{ cursor: 'pointer', width: '173px', marginTop: '-5px' }}
        >
          <Logo />
        </MenuLink>

        <NavLink href="/blog" className={onlyDesktop}>
          <span>
            Blog
          </span>
        </NavLink>

        <button
          className={navToggler}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mt-3 mb-4 my-lg-0">
            <li className="nav-item">
              <NavLink
                href="/blog"
                className={onlyMobile}
                style={{
                  color: '#222', fontSize: 'calc(1.3rem + 0.6vw)',
                  textTransform: 'none', fontWeight: '400'
                }}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                to="/"
                scrollTo="submit"
                className="btn"
                smooth
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
          <ul className={productMenu}>
            <h3>Graboxy Products</h3>
            <li className="nav-item mb-3">
              <a
                href="https://sentinel.graboxy.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={sentinelLogo} alt="Graboxy Sentinel logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://2fa.graboxy.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={twofaLogo} alt="Graboxy 2FA logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://anti-cheat.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={anticheatLogo} alt="Graboxy Anti-Cheat logo" />
              </a>
            </li>
            <li className="nav-item mb-3">
              <a
                href="https://captcha.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={captchaLogo} alt="Graboxy Captcha logo" />
              </a>
            </li>

            <h3 className="mt-4">Company Website</h3>
            <li className="nav-item mb-3">
              <a
                href="https://cursorinsight.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img src={ciLogo} alt="Cursor Insight logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AppHeader;
