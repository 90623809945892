import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ReactComponent as ArrowLeftIcon,
} from '../../images/arrow-left.svg';

import styles from './BackToLink.module.scss';

const BackToLink = ({ label, to, className }) => {
  const classes = [styles.BackToLink, className].join(' ');

  return (
    <Link to={to} className={classes}>
      <ArrowLeftIcon />
      {label}
    </Link>
  );
};

BackToLink.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
};

BackToLink.defaultProps = {
  label: 'Back to home',
  to: '/',
  className: '',
};

export default BackToLink;
