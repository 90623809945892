import React from 'react';

import logo from '../../images/logo.svg';
import styles from './Logo.module.scss';

const Logo = () => (
  <img src={logo} alt="Graboxy Logo" className={styles.Logo} />
);

export const ColLogo = () => (
  <div className="container">
    <div className="row text-center">
      <div className="col-12 py-5">
        <Logo />
      </div>
    </div>
  </div>
);

export default Logo;
