import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';

import { WP_API_URL } from '../../constants';
import formatPost from '../utils/formatPost';

export default function usePost(path = 'posts') {
  const { postId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    setError(undefined);

    axios.get(`${WP_API_URL}/${path}/${postId}?_embed`)
      .then((response) => setPost(response.data))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  }, [postId, path, setIsLoading, setPost, setError]);

  return { isLoading, post: formatPost(post), error };
}
