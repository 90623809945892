import { Link } from 'react-router-dom';

import {
  Form,
  TextInput,
  CheckBox,
  SubmitButton,
} from 'custom-form-client/react';

import Section from '../Section';
import BookMeeting from '../BookMeeting';
import './Contact.scss';

import {
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../../constants';

import play from './play.svg';

const Contact = () => (
  <Section
    id="submit"
    className="Contact"
  >
    <div className="container my-0 py-0">
      <div className="ContactInner">
        <div className="row my-0 py-0">
          <div className="col-lg-7 my-0 py-0">
            <h1>Contact Us</h1>
            <Form
              id="customForm"
              className="form p-0"
              type="contact"
              action={process.env.REACT_APP_CUSTOM_FORM_URL}
              responseMessage="Thank you for your message."
              captchaType="graboxyCaptcha"
              captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
              captchaOptions={{
                urlBase: CAPTCHA_URL_BASE,
                apiKey: GRABOXY_API_KEY,
                trapUrl: TRAP_SERVER_URL,
              }}
            >
              <div className="form__inner">
                <div className="inputGroup">
                  <TextInput
                    id="name"
                    label="Your Name"
                    className="form__inner--row"
                    required
                  />
                </div>
                <div className="inputGroup">
                  <TextInput
                    id="email"
                    type="email"
                    label="Your E-mail"
                    className="form__inner--row"
                    required
                  />
                </div>
                <div className="inputGroup">
                  <TextInput
                    id="company"
                    label="Company Name"
                    className="form__inner--row"
                  />
                </div>
                <div className="inputGroup">
                  <TextInput
                    id="message"
                    label="Message"
                    className="form__inner--row"
                    multiline
                  />
                </div>

                <div className="check text-start">
                  <CheckBox id="subscribe" className="mb-2">
                    Send me news about product updates
                  </CheckBox>

                  <CheckBox id="accept" required>
                    I agree to the
                    {' '}
                    <Link
                      to="/document/privacy-policy"
                      target="_blank"
                      className="text-nowrap"
                    >
                      Privacy Policy
                    </Link>
                    <small>(Required)</small>
                  </CheckBox>
                </div>

                <div className="submit-container">
                  <SubmitButton className="btn">
                    Send
                    {' '}
                    <img src={play} />
                  </SubmitButton>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-lg-5 my-0 py-0">
            <BookMeeting />
          </div>
        </div>
      </div>
    </div>
  </Section>
);

export default Contact;
