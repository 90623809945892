import ReactMarkdown from 'react-markdown';

import useMarkdown from '../../hooks/useMarkdown';
import DocumentLayout from '../../layouts/DocumentLayout';
import privacy from './Privacy.md';
import styles from './Privacy.module.scss';

const Privacy = () => {
  const content = useMarkdown(privacy);

  return (
    <DocumentLayout>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ReactMarkdown
              className={styles.Privacy}
              components={{
                h1: 'h2',
                em: 'span',
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </DocumentLayout >
  );
};

export default Privacy;
